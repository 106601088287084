<template>
    <section :id="sliceId" class="TileBlock">
        <h2
            v-if="slice.primary.section_title"
            class="TitleBlock__section-title"
        >
            <FillTextEffect
                :id="sliceId"
                :trigger-container="'#' + sliceId"
                :text="slice.primary.section_title"
                bottom-position="400px"
            />
        </h2>
        <ul class="TileBlock__list">
            <li v-for="tile in slice.items" class="TileBlock__list-item">
                <CustomLink
                    class="TileBlock__list-item-link"
                    :to="tile.internal_link.url"
                    :href="tile.external_link.url"
                >
                    <div>
                        <p v-if="tile.pretitle" class="TileBlock__pretitle">
                            {{ tile.pretitle }}
                        </p>
                        <h3 v-if="tile.title" class="TileBlock__title">
                            {{ tile.title }}
                        </h3>
                        <ul v-if="tile.title" class="TileBlock__tags">
                            <li v-if="tile.tag_1" class="TileBlock__tag">
                                {{ tile.tag_1 }}
                            </li>
                            <li v-if="tile.tag_2" class="TileBlock__tag">
                                {{ tile.tag_2 }}
                            </li>
                            <li v-if="tile.tag_3" class="TileBlock__tag">
                                {{ tile.tag_3 }}
                            </li>
                        </ul>
                    </div>
                    <div class="TileBlock__icon">
                        <FontIcon icon="arrow" color="white" size="stretch" />
                    </div>
                </CustomLink>
            </li>
        </ul>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.TileSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const sliceId = props.slice.id.replace('$', '-') + '-tile-block'
</script>

<style lang="scss">
.TileBlock {
    grid-column: 2 / span 22;
    margin-top: 8rem;

    @include large-up {
        margin-top: 11.25rem;
    }
}

.TitleBlock__section-title {
    @include heading-2;

    margin-bottom: 2.5rem;
}

.TileBlock__pretitle {
    @include label-sm;

    margin-bottom: 1rem;
}

.TileBlock__title {
    @include heading-2;

    margin-bottom: 1rem;
}

.TileBlock__list {
    display: flex;
    flex-direction: column;
    gap: clamp(0.75rem, 5vw, 3rem);
}

.TileBlock__list-item {
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--lime-green);
    }

    &:hover {
        .TileBlock__list-item-link {
            transform: translate(4px, 4px);
        }

        &:before {
            transform: translate(-4px, -4px);
        }

        .TileBlock__icon {
            transform: rotate(90deg);
        }
    }
}

.TileBlock__list-item-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--black-20);
    position: relative;
    text-decoration: none;
    padding: 2.5rem 1.25rem;
    column-gap: 1rem;

    @include large-up {
        padding: 2.375rem 3.125rem;
    }
}

.TileBlock__list-item:before,
.TileBlock__list-item-link,
.TileBlock__icon {
    transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.TileBlock__tags {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.25rem;
}

.TileBlock__tag {
    display: flex;
    align-items: center;

    &:not(:last-child)::after {
        content: '';
        display: block;
        transform: rotate(45deg);
        width: 0.5rem;
        height: 0.5rem;
        background-color: var(--lime-green);
        margin-left: 0.75rem;
        margin-right: 0.75rem;
    }
}

.TileBlock__icon {
    flex-shrink: 0;
    width: clamp(1rem, 5vw, 2rem);
}
</style>
